import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { SingleMembershipQuery } from '../../graphql-types';

import { BREAKPOINTS, COLORS } from '../ui/variables';
import Button from '../ui/BaseButton';
import renderRichBodyText from '../utils/renderRichText';

import Layout from '../components/layout';
import Hero from '../components/plans/Hero';
import LocationsBanner from '../components/plans/LocationsBanner';
import PicsCarousel from '../components/plans/PicsCarousel';
import Accordion from '../components/founding-member/Accordion';
import Billing from '../components/plans/Billing';
import Benefits from '../components/plans/Benefits';
import Testimonials from '../components/plans/Testimonials';
import Footer from '../components/plans/Footer';
import MembershipSEO from '../components/MembershipSEO';
import { Uppercase as Eyebrow } from '../ui/TypographyStyles';

export const JoinCTA = () => (
  <Button
    css={css`
      padding: 14px 40px;
      font-weight: 700;
      width: fit-content;
      @media ${BREAKPOINTS.TABLET} {
        padding: 13.5px 24px;
      }
    `}
    type='external'
    href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
    color={COLORS.NEW_ORANGE}
  >
    Book Now
  </Button>
);

export const MaxWidthWrapper = styled.div`
  max-width: 1242px;
  margin: 0 auto;
`;

const Values = styled.section`
  background-color: ${COLORS.NEW_BEIGE};
  padding: 140px 100px;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 90px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 80px 20px;
    margin-bottom: 12px;
  }
`;

const FAQ = styled(Values)`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.BLACK};
`;

export const pageQuery = graphql`
  query SingleMembership {
    contentfulPagesSinglePlanMembership {
      heroHeader
      heroParagraph
      heroAepPrice
      valuesSection {
        FAQItems {
          ... on ContentfulAccordionItem {
            id
            question
            answer {
              raw
            }
          }
        }
      }
      billingHeader
      billingBody {
        billingBody
      }
      priceVaccines
      priceBloodTest
      priceFleaTick
      priceFecalTest
      priceNailTrim
      priceAnalGland
      benefitsHeader
      benefits
      faqSection {
        FAQItems {
          ... on ContentfulAccordionItem {
            id
            question
            answer {
              raw
            }
          }
        }
      }
      footerText
    }
  }
`;

interface Props {
  data: SingleMembershipQuery;
}

const Membership = ({ data }: Props) => {
  const membership = data.contentfulPagesSinglePlanMembership;

  const valuesItems = membership.valuesSection.FAQItems.map(item => ({
    value: item.question,
    description: renderRichBodyText({ raw: item.answer.raw, references: null }),
  }));

  const faqItems = membership.faqSection.FAQItems.map(item => ({
    value: item.question,
    description: renderRichBodyText({ raw: item.answer.raw, references: null }),
  }));

  return (
    <Layout>
      <MembershipSEO />

      <Hero
        header={membership.heroHeader}
        paragraph={membership.heroParagraph}
        aepPrice={membership.heroAepPrice}
      />

      <LocationsBanner />

      <PicsCarousel />

      <Values>
        <MaxWidthWrapper>
          <Eyebrow
            css={css`
              margin-bottom: 60px;
              @media ${BREAKPOINTS.MOBILE} {
                margin-bottom: 24px;
              }
            `}
          >
            Why membership?
          </Eyebrow>
          <Accordion faq={valuesItems} initialState={null} font='Adonis' />
          <div style={{ marginBottom: '40px' }} />
          <JoinCTA />
        </MaxWidthWrapper>
      </Values>

      <Billing
        header={membership.billingHeader}
        body={membership.billingBody.billingBody}
        priceVaccines={membership.priceVaccines}
        priceBlood={membership.priceBloodTest}
        priceFlea={membership.priceFleaTick}
        priceFecal={membership.priceFecalTest}
        priceNail={membership.priceNailTrim}
        priceAnal={membership.priceAnalGland}
      />

      <Benefits
        header={membership.benefitsHeader}
        perks={membership.benefits}
      />

      <Testimonials />

      <FAQ>
        <MaxWidthWrapper>
          <Eyebrow
            css={css`
              margin-bottom: 60px;
              @media ${BREAKPOINTS.MOBILE} {
                margin-bottom: 24px;
              }
            `}
          >
            Common questions
          </Eyebrow>
          <Accordion
            faq={faqItems}
            borderColor='white'
            initialState={null}
            font='Gibson'
          />
        </MaxWidthWrapper>
      </FAQ>

      <Footer text={membership.footerText} />
    </Layout>
  );
};

export default Membership;
