import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Heading1 from '../../ui/Heading1';
import Checkmark from '../../images/icons/Union.svg';
import Button from '../../ui/BaseButton';

const Container = styled.section`
  background-color: ${COLORS.NEW_LIGHT_BEIGE};
  padding: 47px 90px;
  @media ${BREAKPOINTS.TABLET} {
    padding: 47px 40px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    padding: 60px 20px;
  }
`;

const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media (max-width: 520px) {
    display: block;
  }
`;

const HeroText = styled.div`
  height: fit-content;
  @media (max-width: 1300px) {
    margin: 0 auto;
  }
  @media ${BREAKPOINTS.MOBILE} {
    margin-top: 0;
  }
`;

const Header = styled(Heading1)`
  font-size: 60px;
  text-transform: capitalize;
  max-width: 740px;
  @media (max-width: 1300px) {
    font-size: 50px;
    text-align: center;
  }
  @media (max-width: 1200px) {
    text-align: center;
  }
  @media (max-width: 900px) {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 48px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    max-width: 420px;
    font-size: 38px;
    text-align: left;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  @media (max-width: 900px) {
    justify-content: space-between;
  }
  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
    width: 100%;
  }
`;

const Box = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.WHITE};
  width: 350px;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
  }
`;

const BoxHeader = styled.span`
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 44px;
  letter-spacing: 3px;
  margin-bottom: 15px;
  margin-left: 10px;
  @media (max-width: 1300px) {
    font-size: 22px;
  }
`;

const List = styled.ul`
  margin: 0;
  margin-bottom: 20px;
  li {
    list-style-image: url(${Checkmark});
    list-style-position: outside;
  }
  li:last-of-type {
    margin-bottom: 0;
  }
  span {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const CTAWrapper = styled.div`
  margin-left: 10px;
`;

const HeroImage = styled.div`
  width: 50%;
  height: fit-content;
  height: -moz-fit-content;
  align-self: center;
  @media (max-width: 1300px) {
    display: none;
  }
`;

const JoinCTA = ({ text }) => (
  <Button
    css={css`
      padding: 14px 30px;
      font-weight: 700;
      width: 265px;
      @media ${BREAKPOINTS.TABLET} {
        padding: 13.5px 24px;
      }
    `}
    type='external'
    href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
    color={COLORS.NEW_ORANGE}
  >
    {text}
  </Button>
);

interface Props {
  header: string;
  paragraph: string;
  aepPrice: number;
}

const Hero = ({ header, paragraph, aepPrice }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      pets: file(relativePath: { eq: "graphics/pets-stack.png" }) {
        childImageSharp {
          fluid(maxHeight: 647, maxWidth: 647) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Wrapper>
        <HeroText>
          <Header>{header}</Header>
          <OptionsContainer>
            <Box
              css={css`
                margin-right: 45px;
                @media (max-width: 900px) {
                  margin-right: 0;
                }
                @media ${BREAKPOINTS.MOBILE} {
                  margin-bottom: 20px;
                }
              `}
            >
              <BoxHeader>Membership</BoxHeader>
              <List>
                <li>
                  <span>
                    {/* note to easily find hard-coded prices for future update */}
                    Annual&nbsp;exam&nbsp;included&nbsp;($125&nbsp;value)
                  </span>
                </li>
                <li>
                  <span>20%&nbsp;off&nbsp;additional&nbsp;exams</span>
                </li>
                <li>
                  <span>24/7&nbsp;telehealth</span>
                </li>
              </List>
              <CTAWrapper>
                {/* note to easily find hard-coded prices for future update */}
                <JoinCTA text={`Join for $${aepPrice ?? 179}/year`} />
              </CTAWrapper>
            </Box>
            <Box>
              <BoxHeader>Pay as you go</BoxHeader>
              <List>
                <li>
                  <span>No&nbsp;annual&nbsp;commitment</span>
                </li>
                <li>
                  <span>Exceptional&nbsp;veterinary&nbsp;care</span>
                </li>
                <li>
                  <span>Best-in-class&nbsp;facilities</span>
                </li>
              </List>
              <CTAWrapper>
                {/* note to easily find hard-coded prices for future update */}
                <JoinCTA text='Book&nbsp;for&nbsp;$125/exam' />
              </CTAWrapper>
            </Box>
          </OptionsContainer>
        </HeroText>
        <HeroImage>
          <Img
            fluid={data.pets.childImageSharp.fluid}
            alt='Cute doodle of two cats and a dog.'
          />
        </HeroImage>
      </Wrapper>
    </Container>
  );
};

export default Hero;
